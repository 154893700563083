.App {
  text-align: center;
}

body, html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-size: 2.5em;
}
h2 {
  font-size: 2em;
}

.App-link {
  color: #61dafb;
}

.project-card-container {
  position: relative;
  height: 100%; /* take the full height of its parent */
}

.card {
  position: absolute;
  top: -15vh; /* Adjust this value to move the card up */
  width: 100%; /* make sure the width is 100% of its container */
}

.custom-card-header-title {
  font-size: 1.5rem; /* Adjust as needed */
}

.two-columns-list {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
  list-style-type: none; /* To remove the bullets */
  padding: 0;
}

/* Example of additional custom styles */
.columns {
  height: 60vh;
}

.button.is-link.is-fullwidth {
  text-align: left;
}

/* Style for unselected buttons */
.button.project-button {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #cacaca;
  text-align: left;
  width: 100%; /* make the button fill the width of the container */
  display: block; /* display button as block to stack them vertically */
  margin-bottom: 0px; /* space between buttons */
  border-radius: 0px;
}

/* Style when mouse hovers over button */
.button.project-button:hover {
  background-color: #f0f0f0;
}

/* Style for selected button */
.button.project-button.is-active {
  background-color: #eec2c2;
}

/* Padding for the columns */
.columns {
  padding-left: 10px;
  padding-right: 10px;
}



/* Define styles for night mode */
.night-mode {
  background-color: #1a1a1a; /* dark grey background */
  color: #e0e0e0; /* light grey text color */
}

.night-mode .navbar {
  background-color: #333333; /* slightly lighter dark grey for navbar */
}

.night-mode .button {
  background-color: #333333; /* Button Background Color */
  color: #e0e0e0; /* Button Text Color */
  border-color: #4a4a4a; /* Dark grey border */
}

.night-mode .button:hover {
  background-color: #4a4a4a; /* slightly lighter grey when hovering over button */
}

body.night-mode, html.night-mode {
  background-color: #1a1a1a; /* Dark mode background color */
  color: white; /* Dark mode text color */
}

body.night-mode .card .card-header, 
html.night-mode .card .card-header {
  background-color: #282c34; /* or the color of your navbar in night mode */
}

/* Night mode card content */
body.night-mode .card .card-content, 
html.night-mode .card .card-content {
  background-color: #212121; /* or any color slightly lighter than the main background */
}





/* ABOUT ME FORMATTING */
.about-container {
  max-width: 300vh;
  margin: auto;
  padding: 20px;
  text-align: center;
  font-size: 16pt;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.profile-pic {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  margin-right: 5vh;
  align-self: flex-start;
  margin-top: 5vh;
}

.about-text {
  max-width: 125vh;
}
